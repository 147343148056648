//
// Flash messages
//

$flash-messages-fixed-z: 10000;

.flash-messages-fixed {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: auto;
    background-color: transparent;
    z-index: $flash-messages-fixed-z;
    .alert {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        padding: 20px 40px;
        opacity: 1;
        transition: opacity 500ms ease;
        &.fadeout-delay-0 {
            transition-delay: 0ms;
            opacity: 0;
        }
        &.fadeout-delay-5000 {
            transition-delay: 5000ms;
            opacity: 0;
        }
        &.alert-success {
            background-color: #0f6848;
        }
        &.alert-info {
            background-color: #1e3bc4;
        }
        &.alert-danger {
            background-color: #b20a00;
        }
        &.alert-warning {
            background-color: #fcc715;
        }
        button.close {
            display: inline-flex;
            justify-content: center;
            padding: 0;
            margin-right: 10px;
            width: 25px;
            height: 25px;
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 15px;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
        }
        .message {
            color: #fff;
            font-size: 14px;
        }
    }
}

.flash-message-form {
    color: #000;
    background-color: #fcc715;
    padding: 16px;
    &__contact-car {
        margin-top: 40px;
    }
    &__contact {
        margin-top: 0;
        margin-bottom: 25px;
    }
    &__title {
        font-size: 18px;
    }
    &__error {
        padding: 4px 0 8px 0;
        line-height: 1.3;
        font-size: 14px;
    }
}
